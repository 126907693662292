<template>
  <div class="margen">
    <v-row>
      <v-col class="info-card">
        <v-card
          width="80%"
          height="200px"
          elevation="24"
          :img="require('../assets/Area/mineria.svg')"
          class="white--text mx-auto text-center"
          @click="filtrar('1');"
        >
          <h2 class="area">Mining and Manufacturing Applications</h2>
        </v-card>
      </v-col>

      <v-col class="info-card">
        <v-card
          width="80%"
          height="200px"
          elevation="24"
          :img="require('../assets/Area/imagenes.svg')"
          class="white--text mx-auto text-center"
          @click="filtrar('2');"
        >
          <h2 class="area">Biomedical Image Processing and Classification</h2>
        </v-card>
      </v-col>

      <v-col class="info-card">
        <v-card
          width="80%"
          height="200px"
          elevation="24"
          :img="require('../assets/Area/neurociencia.svg')"
          class="white--text mx-auto text-center"
          @click="filtrar('3');"
        >
          <h2 class="area">Neuroscience</h2>
        </v-card>
      </v-col>
    </v-row>
        <v-card
          dark
          class="mx-auto mt-5 text-center info-card2"
          width="90%"
          color="transparent"
          elevation="24"
        >
          <h1>PUBLICATIONS</h1>
        </v-card>
        <v-list three-line color="transparent" class="mx-auto" width="90%">  
            <v-list-item v-for="(item,i) in publicacionesAct" :key="i" class="my-9 info-card3">
            <v-card class="mx-auto" width="100%" color="#f6f4f5" elevation="24">
              <v-list-item-content>
                <v-list-item-action-text ><h2>{{item.nombre}}</h2></v-list-item-action-text>
                <br/>
                <v-list-item-subtitle>{{item.autores}}</v-list-item-subtitle>
                <br/>
                <v-list-item-subtitle>{{item.publisher}} ({{item.year}})</v-list-item-subtitle>
              </v-list-item-content>
            </v-card>
            </v-list-item>
        </v-list>
  </div>
</template>

<script>

export default{
  name:'Publications',
  data () {
    return {
      publicacionesAct: [],
      publicaciones:[
        {
          nombre:'Impact of transfer learning for human sperm segmentation using deep learning',
          autores:'Ruth Marín and Violeta Chang',
          publisher:'Computers in Biology and Medicine (CIBM), Volume 136',
          year:'2021',
          area:'2'
        },
        {
          nombre:'Generation of a HER2 Breast Cancer Gold-Standard Using Supervised Learning from Multiple Experts. In: Stoyanov D. et al. (eds) Intravascular Imaging and Computer Assisted Stenting and Large-Scale Annotation of Biomedical Data and Expert Label Synthesis',
          autores:'Violeta Chang',
          publisher:'Lecture Notes in Computer Science, vol 11043',
          year:'2018',
          area:'2'
        },
        {
          nombre:'Automatic classification of human sperm head morphology',
          autores:'Violeta Chang, Laurent Heutte, Caroline Petitjean, Steffen Härtel, and Nancy Hitschfeld',
          publisher:'Computers in Biology and Medicine (CIBM), 84: 205--216',
          year:'2017',
          area:'2'
          
        },
        {
          nombre:'Gold-standard for computer-assisted morphological sperm analysis',
          autores:'Violeta Chang, Alejandra García, Nancy Hitschfeld, and Steffen Härtel',
          publisher:'Computers in Biology and Medicine (CIBM), 83: 143--150',
          year:'2017',
          area:'2'
          
        },
        {
          nombre:'Gold-standard and improved framework for sperm head segmentation',
          autores:'Violeta Chang, Jose M. Saavedra, Victor Castañeda, Luis Sarabia, Nancy Hitschfeld, and Steffen Härtel',
          publisher:'Computer Methods and Programs in Biomedicine (CMPB), 117(2): 225--237',
          year:'2014',
          area:'2'
          
        },
        {
          nombre:'Pattern recognition applied to seismic signals of llaima volcano (Chile): An evaluation of station-dependent classifiers',
          autores:'Curilem, M., Huenupan, F., Beltran, D., San Martin, C., Fuentealba, G., Franco, L., Cardona, C., Acuña, G., Chacon, M., Salman Khan, M., Becerra Yoma, N.',
          publisher:'Journal of Volcanology and Geothermal Research, 315; 15-27',
          year:'2016',
          area:'1'
          
        },
        {
          nombre:'Software sensors for biomass concentration in a SSC process using Artificial Neural Networks and Support Vector Machine',
          autores:'Acuña, G., Ramírez, C., Curilem, M.,',
          publisher:'Bioprocess and Biosystems Engineering, 37(1): 27-36',
          year:'2014',
          area:'1'
          
        },
        {
          nombre:'Development of a software sensor based on a NARMAX-Support Vector Machine Model for Semi-Autogeneous Grinding',
          autores:'Acuña, G., Curilem, M, Cubillos, F.',
          publisher:'Revista Iberoamericana de Automática e Informática Industrial, 11(1): 109-116',
          year:'2014',
          area:'1'
          
        },
        {
          nombre:'Rotary Dryer Control using a Grey-Box Neural Model Scheme',
          autores:'F. Cubillos, E. Vhymeister, G. Acuña, P. Alvarez',
          publisher:'Drying Technology, 29(15): 1820-1827',
          year:'2011',
          area:'1'
          
        },
        {
          nombre:'Silhouette-based human action recognition with a multi-class support vector machine',
          autores:'Gonzalez, L., Velastin, S., Acuña G.',
          publisher:'9th International Conference on Pattern Recognition Systems (ICPRS 2018), IET Conference Publications 2018(CP745), pp. 80-84',
          year:'2018',
          area:'1'
          
        },
        {
          nombre:'Randomized Neural Networks for Recursive System Identification in the Presence of Outliers: A Performance Comparison',
          autores:'Cesar Mattos, Guilherme Barreto, Gonzalo Acuña',
          publisher:'Lecture Notes in Computer Science (including subseries Lecture Notes in Artificial Intelligence and Lecture Notes in Bioinformatics)Volume 10305 LNCS, 2017, Pages 603-615 14th International Work-Conference on Artificial Neural Networks, IWANN',
          year:'2017',
          area:'1'
          
        },
        {
          nombre:'Indirect training of gray-box models using LS-SVM and genetic algorithms',
          autores:'Gonzalo Acuña and Hans Moller',
          publisher:'The Latin American Congress on Computational Intelligence, 2016 IEEE Latin American Conference on Computational Intelligence, LA-CCI',
          year:'2016',
          area:'1'
          
        },
        {
          nombre:'Feature Selection for Discrimination between Volcanic and Tectonic Events of The Llaima Volcano (Chile)',
          autores:'Millaray Curilem, Camilo Soto, Fernando Huenupan, Cesar San Martin, Carlos Cardona, Luis Franco, Gonzalo Acuña, Max Chacon, M. Salman Khan, Nestor Becerra Yoma',
          publisher:'IET Seminar Digest Volume 2016, Issue 2, 2016 International Conference on Pattern Recognition Systems, ICPRS',
          year:'2016',
          area:'1'
          
        },
        {
          nombre:'NARX Neural Network Model for Predicting Availability of a Heavy Duty Mining Equipment',
          autores:'Gonzalo Acuña, Millaray Curilem, Francisco Cubillos, Beatriz Araya, Guisselle Segovia, Carlos Perez and Cristian Huanquilef',
          publisher:'The Latin American Congress on Computational Intelligence, LA-CCI',
          year:'2015',
          area:'1'
          
        },
        {
          nombre:'Prediction of the Criticality of a Heavy Duty Mining Equipment',
          autores:'Millaray Curilem, Gonzalo Acuña, Cristian Huanquilef, Francisco Cubillos, Beatriz Araya, Guiselle Segovia and Carlos Pérez',
          publisher:'The Latin American Congress on Computational Intelligence, LA-CCI',
          year:'2015',
          area:'1'
          
        },
        {
          nombre:'Feature analysis for the classification of volcanic seismic events using Support Vector Machines',
          autores:'Curilem M., Huenupan F., San Martin C., Fuentealba G., Cardona C., Franco L., Acuña G.',
          publisher:'A. Gelbukh et al. (Eds.): MICAI 2014',
          year:'2014',
          area:'1'
          
        },
        {
          nombre:'Predictive Models applied to a Heavy Duty Equipment Management',
          autores:'G. Acuña, M. Curilem, B. Araya, F. Cubillos, R. Miranda, F. Garrido',
          publisher:'MICAI 2014 Part II, Lecture Notes in Artificial Intelligence, LNAI 8857:198-215',
          year:'2014',
          area:'1'
          
        },
        {
          nombre:'Time-variant parameter estimation using a SVM gray-box model: application to a CSTR process',
          autores:'Acuña, G., Curilem, M.',
          publisher:'Proceedings of the 3rd International Conference on Systems and Control, ICSC13',
          year:'2013',
          area:'1'
          
        },
        {
          nombre:'A Svm Gray-box Model for a Solid Substrate Fermentation Process',
          autores:'Acuña, G., Gonzáles, J., Curilem, M., Cubillos, F.',
          publisher:'Chemical Engineering Transactions, 35: 961-966',
          year:'2013',
          area:'1'
          
        },
        {
          nombre:'Software sensors for biomass concentration in a SSC process using Artificial Neural Networks and Support Vector Machine',
          autores:'Acuña, G., C. Ramirez, M. Curilem',
          publisher:'Proceedings of the IEEE 20th Mediterranean Conference on Control and Automation, MED',
          year:'2012',
          area:'1'
          
        },
        {
          nombre:'Comparing NARX and NARMAX models using ANN and SVM for cash demand forecasting for ATM',
          autores:'Acuña, G., C. Ramirez, M. Curilem',
          publisher:'Proceedings of the IEEE International Joint Conference on Neural Networks, IJCNN',
          year:'2012',
          area:'1'
          
        },
        {
          nombre:'Análisis de rendimiento académico estudiantil usando data warehouse y redes neuronales',
          autores:'Zambrano, C., Rojas, D., Carvajal, K., Acuña G.',
          publisher:'Ingeniare, Revista Chilena de Ingeniería',
          year:'2011',
          area:'1'
          
        },
        {
          nombre:'Neural networks and support vector machine models applied to energy consumption optimization in semiautogenous grinding',
          autores:'Curilem, M., Acuña, G., Cubillos, F. and Vhymeister, E.',
          publisher:'Chemical Engineering Transactions, 25: 761-766',
          year:'2011',
          area:'1'
          
        },
        {
          nombre:'Forecasting cash demand in ATM using Neural Networks and Least Square Support Vector Machine',
          autores:'C. Ramírez, G. Acuña',
          publisher:'Lecture Notes in Computer Science , LNCS 7042',
          year:'2011',
          area:'1'
          
        },
        {
          nombre:'Design of a low-cost neuromuscular blockade monitoring device',
          autores:'L. E. Medina, M. Villalobos-Cid, A. Alvarez, and P. Chana-Cuevas',
          publisher:'5th International Conference on Intelligent Informatics and Biomedical Sciences (ICIIBMS)',
          year:'2020',
          area:'3'
          
        },
        {
          nombre:'Creating a neuroprosthesis for active tactile exploration of textures',
          autores:'J. E. O´Doherty, S. Shokur, L. E. Medina, M. A. Lebedev, and M. A. L. Nicolelis',
          publisher:'Proc. Natl. Acad. Sci., vol. 116, no. 43',
          year:'2019',
          area:'3'
          
        },
        {
          nombre:'Quantification of Dorsal Column Fiber Responses in a Model of Kilohertz-Frequency Spinal Cord Stimulation',
          autores:'L. E. Medina, N. D. Crosby, J. J. Janik, and W. M. Grill',
          publisher:'NANS-NIC Joint Meeting',
          year:'2016',
          area:'3'
          
        },
        {
          nombre:'Nerve excitation using an amplitude-modulated signal with kilohertz-frequency carrier and non-zero offset',
          autores:'L. E. Medina and W. M. Grill',
          publisher:'J. Neuroeng. Rehabil., vol. 13, no. 1, p. 63',
          year:'2016',
          area:'3'
          
        },
        {
          nombre:'Effects of frequency-dependent membrane capacitance on neural excitability',
          autores:'B. Howell, L. E. Medina, and W. M. Grill',
          publisher:'J. Neural Eng., vol. 12, no. 5, p. 056015',
          year:'2015',
          area:'3'
          
        },
        {
          nombre:'Phantom Model of Transcutaneous Electrical Stimulation with Kilohertz Signals',
          autores:'L. E. Medina and W. M. Grill',
          publisher:'IEEE EMBS Conference on Neural Engineering',
          year:'2015',
          area:'3'
          
        },
        {
          nombre:'Volume conductor model of transcutaneous electrical stimulation with kilohertz signals',
          autores:'L. E. Medina and W. M. Grill',
          publisher:'J. Neural Eng., vol. 11, no. 6, p. 066012',
          year:'2014',
          area:'3'
          
        },
        {
          nombre:'Circuit and volume conductor models of transcutaneous electrical stimulation',
          autores:'L. E. Medina and W. M. Grill',
          publisher:'IEEE/EMBS Conf. Neural Eng. NER, vol. C, pp. 1473-1476',
          year:'2013',
          area:'3'
          
        },
        {
          nombre:'Stochastic Facilitation of Artificial Tactile Sensation in Primates',
          autores:'L. E. Medina, M. A. Lebedev, J. E. O´Doherty, and M. A. L. Nicolelis',
          publisher:'J. Neurosci., vol. 32, no. 41, pp. 14271-14275',
          year:'2012',
          area:'3'
        },
        {
          nombre:'Ore grade estimation by feature selection and voting using boundary detection in digital image analysis',
          autores:'C. A. Perez, P.A. Estevez, P. Vera, L. Castillo, D. Schulz, L.E. Medina',
          publisher:'J. Miner. Process., vol. 101, no. 1-4, pp. 28-36',
          year:'2011',
          area:'3'
        },
        {
          nombre:'A critical experimental study of the classical tactile threshold theory',
          autores:'C. A. Perez, J. R. Donoso, and L. E. Medina',
          publisher:'BMC Neurosci., vol. 11, p. 76',
          year:'2010',
          area:'3'
        },
        
      ]
    }
  },
  methods:{
    filtrar(id){
      var i = Number;
      this.publicacionesAct = [];

      for(i=0; i < this.publicaciones.length ; i++){
        if( id == this.publicaciones[i].area){
          this.publicacionesAct.push(this.publicaciones[i])
        }
      }
    }
  },
  mounted(){
    this.publicacionesAct = this.publicaciones
  }
}

</script>

<style scoped>
.margen{
    margin-bottom:96px;
    margin-top:20px;
  }
  .info-card{
      opacity: 0;
      animation: slide-top 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }
    @keyframes slide-top {
      0% {
        opacity: 0;
        transform: translateY(1000px);
      }
      100% {
        opacity: 1;
        transform: translateY(0px);
      }
    } 
  .info-card2{
      opacity: 0;
      animation: slide-top2 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }
    @keyframes slide-top2 {
      0% {
        opacity: 0;
        transform: translateY(1000px);
      }
      100% {
        opacity: 1;
        transform: translateY(0px);
      }
    }

  .info-card3{
      opacity: 0;
      animation: slide-top 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }
    @keyframes slide-top {
      0% {
        opacity: 0;
        transform: translateY(1000px);
      }
      100% {
        opacity: 1;
        transform: translateY(0px);
      }
    }

  .area{
    background-color: #000000;
    margin: 0 auto;
    width: 100%;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  h3{
    
    font-size:120%;
    font-weight: normal;
  }
  h2{
    margin: 0 8px;
  }
  h4{
    margin: 0 8px;
    font-size: 110%;
    color:grey;
    font-weight: normal;
  }
</style>